p,
a,
input,
h1,
h2,
h3,
textarea {
  font-family: "QS", sans-serif;
}
* {
  -webkit-font-smoothing: subpixel-antialiased;
}
::-webkit-scrollbar-thumb {
  background-color: #2a0c52;
  border-radius: 4px;
  border: 1px solid #ffffff42;
}
::-webkit-scrollbar-track {
  background: #2a0c52;
}

/* width */
::-webkit-scrollbar {
  width: auto;
  height: auto;
}

img.appstore-logo {
  min-width: 200px;
  margin: 24px 0;
}

.centre {
  text-align: center;
}
.auto-margin {
  margin: 12px auto !important;
}
.container {
  background: #1b0d2e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #eddfd1;
  padding: 0px 8vw;
}
.safe-area {
  padding-top: 92px;
  padding-bottom: 92px;
}
.low-width {
  width: 50%;
}
p.notification-text {
  color: #b50000;
}
@font-face {
  font-family: "QS";
  src: url("../public/assets/fonts/Quicksand-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
footer {
  font-size: 12px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 12px;
  background: #2a0c52;
}

footer a {
  color: #eddfd1;
  text-decoration: none;
  margin: 0 12px 0 12px;
  font-size: 14px;
  font-family: "QS";
}
nav {
  position: fixed;
  width: 100%;
  background: #040304;
  display: flex;
  max-height: 92px;
}
nav a {
  font-size: 18px;
  text-transform: uppercase;
  color: #eddfd1;
  font-family: "QS";
  text-decoration: none;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin: 12px 0;
  align-self: center;
  max-width: 240px;
}
a.btn.login {
  margin-left: auto;
  margin-right: 24px;
}
p {
  font-size: 24px;
}
.low-width p {
  font-size: 18px;
}
h1 {
  font-size: 24px;
}

h2 {
  font-size: 22px;
}
h3 {
  font-size: 20px;
}

p {
  width: 100%;
}
center {
  text-align: center;
}
.container.long-text-container {
  padding-bottom: 80px;
  padding-top: 80px;
}
li {
  font-size: 18px;
}
.dream-shared {
  flex-direction: row;
}
.dream {
  font-size: 2.5vw;
  line-height: 1.25;
  padding-bottom: 120px;
  padding-top: 120px;
  font-weight: bold;
  text-align: justify;
  width: 85%;
  margin: 0 4vw 0 4vw;
}
.qr-bar {
  width: 15%;
}
img.qr-code {
  border-radius: 18px;
  width: 100%;
  max-width: none;
}

.app-logo {
  max-width: 250px;
  border-radius: 16px;
}

p.usable-text {
  font-size: 16px;
}

input {
  border-radius: 8px;
  padding: 12px;
  width: -webkit-fill-available;
  border: none;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.5);
  color: #eddfd1;
  margin: 12px 0;
}
textarea {
  border-radius: 8px;
  padding: 12px;
  width: -webkit-fill-available;
  border: none;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.5);
  color: #eddfd1;
  margin: 12px 0;
}

.login-container {
  text-align: center;
  max-width: 420px;
}
p.terms {
  font-size: 12px;
}
a {
  color: #eddfd1;
}
input:focus {
  outline: none;
}
a.btn {
  background: #a744da;
  border-radius: 8px;
  padding: 12px 24px;
  display: block;
  margin: 24px 0;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  max-width: 240px;
}
.marketing-text {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}
.dream-container {
  background: rgba(255, 255, 255, 0.075);
  border-radius: 8px;
  padding: 12px 24px;
  margin: 8px;
  margin: 24px 0;
  text-transform: capitalize;
  border: solid 1px rgba(255, 255, 255, 0.075);
}

p.dream-text {
  color: #eddfd1;
  font-size: 16px;
  text-transform: initial;
}
p.dream-text.date {
  text-transform: uppercase;
  font-size: 12px;
}

a {
  cursor: pointer;
}

.dream-container.red {
  background: rgb(116 15 15 / 32%);
  min-height: 32px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.symbol {
  font-size: 14px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 6px 12px;
  margin-right: 6px;
}

/*mobile*/

@media only screen and (max-width: 768px) {
  .dream {
    font-size: 100%;
    padding-top: 120px;
    padding-bottom: 120px;
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
  .qr-bar {
    display: none;
  }
  .low-width {
    width: 100%;
  }
  footer a {
    font-size: 10px;
  }
}
